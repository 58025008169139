import React from 'react'

function NoPage() {
  return (
<main id="content-container" class="content-container" role="main" tabindex="-1">

  
<article itemscope="" itemtype="http://schema.org/WebPage">

              <header class="title title--primary">
  <div class="wrapper">

    
    
    
    
    
    
          <h1 class="title__label" itemprop="headline">
      Page not found       </h1>
    
    
            <div class="title__description" itemprop="description">
        The page you were looking for appears to have been moved, deleted, or does not exist. Let us help get you on the right path to finding what you're looking for.        </div>
    
  </div>
</header>


  
  <div class="zones" data-columns="1">

    
      
      
      
      <div class="zone" itemprop="mainContentOfPage">
          <div class="band" data-columns="3">
  <div class="wrapper">
    <h2 class="band__title">Try one of the following:</h2>
    <div class="band__parts">
      <div class="band__part">
        <h3 class="title title--tertiary">
          <a href="/">Retrace your steps</a>
        </h3>
        <p>
          Try starting back at the home page and see if you can navigate to what you’re looking for.
        </p>
      </div>
      <div class="band__part">
        <h3 class="title title--tertiary">
          <a href="https://www.nccourts.gov/help-topics">Explore our help topics</a>
        </h3>
        <p>
          Common answers around the state court system and its process.
        </p>
      </div>
      <div class="band__part">
        <h3 class="title title--tertiary">
          <a href="https://www.nccourts.gov/services">Explore our services</a>
        </h3>
        <p>
          Available services – such as online payments – provided by the state court system.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="band">
  <div class="wrapper">
    <div class="band__parts">
      <div class="band__part">
        <div class="alert alert--secondary" role="alert" aria-live="assertive">
          <strong>Want to talk with someone?</strong>
          If you're still having trouble finding something, <a href="https://www.nccourts.gov/contact-technical-support">send our technical support team a message</a> and we will work with you on finding a solution.
        </div>
      </div>
    </div>
  </div>
</div>
      </div>

                                  
                          
      
      
  </div>

</article>

    

</main>
  )
}

export default NoPage;
